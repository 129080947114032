import React from 'react';
import './../css/loader.css';

const Loader = () => {
    return (
        <div className='loader__wrapper'>
            <div className='loader'></div>
        </div>
    )
}

export default Loader; 