import React, { Component, ReactNode } from "react";
import classNames from "classnames";
import { Route, Switch, withRouter, RouteComponentProps } from "react-router-dom";
import { ScrollPanel } from "primereact/components/scrollpanel/ScrollPanel";
import { createBrowserHistory } from "history";
import rectangular from './assets/images/mettler_images/Rectangle_light.svg';
import fileDocument from '../src/assets/images/mettler_images/file-document_dark.svg';
import userEdit from '../src/assets/images/mettler_images/usersImage_dark.svg';
import setting from '../src/assets/images/mettler_images/settingImage_dark.svg';
import dashboard from '../src/assets/images/mettler_images/RiDashboardLine.svg';
import groupImage from '../src/assets/images/mettler_images/group.svg';
import organizationImage from '../src/assets/images/mettler_images/organizationImage.png';
import patientStaffImage from '../src/assets/images/mettler_images/patientStaffImage.svg';
import patientBedImage from '../src/assets/images/mettler_images/patientBedAssignImage.svg';
import { SelectChangeEvent } from '@mui/material/Select';
import "./containers/pages/Bed/BedMasterConfiguration.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { AppTopbar } from "./AppTopbar";
import "./layout/layout.css";
import "./App.css";
import * as Constants from "../src/containers/pages/Constants/ConstantValues";
import LeftPanel from "./components/AppLeftMenueComponent/Leftmenue";
import NavigationMenu from "./components/AppLeftMenueComponent/LeftmenueBar";


const AdminDashboardPage = React.lazy(() => import('./containers/pages/Admin/AdminDashboard'));
const BedAssignInformationPage = React.lazy(() => import('./containers/pages/Bed/BedAssignInformation/BedAssignInformation'))
const BedMasterConfiguration = React.lazy(() => import('./containers/pages/Bed/Bed_Master_Configuration/Overview_Bed_Master_Configuration'));
const DynamicBedAssignPage = React.lazy(() => import('./containers/pages/Bed/Dynamic_Bed_Assignment/Overview_Dynamic_Bed_Assignemnt'));
const ForgotPasswordPage = React.lazy(() => import('./containers/pages/Login/Forgotpassword'));
const LoginPage = React.lazy(() => import('./containers/pages/Login/Login'));
const OtpEnterValidPage = React.lazy(() => import('./containers/pages/Login/OtpEnterValid'));
const PasscodePage = React.lazy(() => import('./containers/pages/Login/SecretKey'));
const SetPassword = React.lazy(() => import('./containers/pages/Login/SetNewPassword'));
const AddOrganizationPage = React.lazy(() => import('./containers/pages/Organization/AddOrganization/AddOrganization'));
const AllOrganizationList = React.lazy(() => import('./containers/pages/Organization/AllOrganizationDetails'));
const OrganizationDetailsPage = React.lazy(() => import('./containers/pages/Organization/OrganizationDetails/OrganizationDetails'));
const AddAllergy = React.lazy(() => import('./containers/pages/Patient_Data/Allergy/Overview_Add_Allergy'));
const AddPatientProblem = React.lazy(() => import('./containers/pages/Patient_Data/Patient_Problem/Overview_Add_Patient_Problem'));
const AddPatientVitals = React.lazy(() => import('./containers/pages/Patient_Data/Patient_Vitals/Overview_Add_Patient_Vitals'));
const AddMultiVital = React.lazy(() => import('./containers/pages/Patient_Data/Patient_Multi_Vital/Overview_Add_Patient_Multi_Vital'));
const AddPatientImmunization = React.lazy(() => import('./containers/pages/Patient_Data/Immunization/OverView_Add_Immune_Patient'));
const AddProcedure = React.lazy(() => import('./containers/pages/Patient_Orders/Procedure/Overview_Add_Procedure'));
const AddImagingProcedure = React.lazy(() => import('./containers/pages/Patient_Orders/Imaging_Procedure/Overview_Add_Imaging_Procedure'));
const AddPatientLabTest = React.lazy(() => import('./containers/pages/Patient_Orders/Lab_Test/Overview_Add_Lab_Test'));
const AddProcedureconsult = React.lazy(() => import('./containers/pages/Patient_Orders/Consult/Overview_Add_Consult'));
const SkinAllergy = React.lazy(() => import('./containers/pages/Patient_Data/AllergyView/AllergyOverView'));
const PatientProblem = React.lazy(() => import('./containers/pages/Patient_Data/PatientProbleView/PatientProbleView'));
const AddImmunizationView = React.lazy(() => import('./containers/pages/Patient_Data/ImmunizationView/ImmunizationViewOverView'));
const ImagingProcedureOverView = React.lazy(() => import('./containers/pages/Patient_Orders/Imaging_ProcedureView/ImagingProcedureOverView'));
const Lab_TestViewOverView = React.lazy(() => import('./containers/pages/Patient_Orders/Lab_TestView/Lab_TestViewOverView'));
const ProcedureView = React.lazy(() => import('./containers/pages/Patient_Orders/ProcedureView/ProcedureOverview'));
const AdmitPatientPage = React.lazy(() => import('./containers/pages/AdmitPatient/AdmitPatientOverview'));
const AdmitPatientupdated = React.lazy(() => import('./containers/pages/Patient_Visit/Overview_Add_Visit_Patient'));
const Configaddproblem = React.lazy(() => import('./containers/pages/Configuration/Add_Configuration_Problem/Overview_Add_Configuration_Problem'));
const Configuration = React.lazy(() => import('./containers/pages/Configuration/Overview_Configuration_Content'));
const Createnewschedule = React.lazy(() => import('./containers/pages/Create_New_Schedule/Overview_Create_New_Schedule'));
const CreatePatientPage = React.lazy(() => import('./containers/pages/Create_Update_Patient/Overview_PatientCU'));
const NursetoNursePdf = React.lazy(() => import('./containers/pages/Nurse_To_Nurse_Report/Overview_Nurse_To_Nurse_Report'));
const OverViewPDPage = React.lazy(() => (import('./containers/pages/PatientDetails/OverViewPD')));
const PatientInfoPage = React.lazy(() => import('./containers/pages/Patient/PatientInfo'));
const PreAdmitAssessmentPage = React.lazy(() => import('./containers/pages/Pre_Admit_Assessment/Overview_Pre_Admit_Assessment_Content'));
const PreAdmitFileUploadPage = React.lazy(() => import('./containers/pages/Pre_Admit_Assessment_File_Upload/Overview_Pre_Admit_Assessment_File_Upload'));
const Q15Reports = React.lazy(() => import('./containers/pages/Q15_Reports/Overview_Q15_Reports_Content'));
const ViewProcedure = React.lazy(() => import('./containers/pages/Patient_Orders/ConsultView/ConsultOverView'));
const VisitPatientDetailsPage = React.lazy(() => import('./containers/pages/Patient_Visit_Dashboard/VisitPatientDetails/VisitPatientDetails'));
const AllStaffDetailsPage = React.lazy(() => import('./containers/pages/Staff/Staff_Details/AllStaffDetails'));
const StaffInfoPage = React.lazy(() => import('./containers/pages/Staff/StaffInfo'));
const PatientStaffAssignmentPage = React.lazy(() => import('./containers/pages/Staff_Patient/Overview_Assignment_Details'));
const PatientStaffAssignDetailsPage = React.lazy(() => import('./containers/pages/Staff_Patient/PatientStaffAssignDetails'));
const ViewFields = React.lazy(() => import('./containers/pages/Forms/CreateFormDetails/CreateFormDetails'));
const VisitPatientdata = React.lazy(() => import('./containers/pages/Patient_Visit_Dashboard/Overview_Patient_Visit_Dashboard'));
const DynamicForm = React.lazy(() => import('./containers/pages/Forms/DynamicForm'));
const MTPForm = React.lazy(() => import('./containers/pages/MTPForm/MTPForm'));
const AscensionFileUploadPage = React.lazy(() => import('./containers/pages/Integration/AscensionFileUpload/AscensionFileUpload'));
const AscensionFilePatientDataPage = React.lazy(() => import('./containers/pages/Integration/AscensionFilePatientData/AscensionFilePatientData'));
const AscensionVoiceToMp3ConvertPage = React.lazy(() => import('./containers/pages/Integration/AscensionVoiceToMp3Convert/AscensionVoiceToMp3Convert'));
const Templet = React.lazy(() => import('./containers/pages/Forms/Components/Templet/templet'))
interface IAppState {
  timeout: number,
  showModal: boolean,
  userLoggedIn: boolean,
  isTimedOut: boolean,
  isloading: boolean,
  staticMenuInactive: boolean,
  overlayMenuActive: boolean,
  mobileMenuActive: boolean,
  isLogged: boolean,
  inActive: boolean,
  iconmenuActive: boolean,
  layoutMode: string,
  layoutColorMode: string,
  UserDisplayName: string,
  userType: string,
  staffName: string,
  searchTerm: string,
  openDialog: boolean,
  role: [],
  rolebyid: [],
  rolepermission: [],
  roleid: string,
  isSkipHeader: boolean,
  Aaroles: string;
  preiass: boolean,
  value: string,
  getPatientDataItems: any,
  selectedPatientId: string,
  newTreatmentPlan: any,
  newFormName: any,
  encryptPatientid: string,
  encryptVisitid: string,
}

const iPAddress = Constants.IpAddress;
interface Props extends RouteComponentProps { }
class App extends Component<Props, IAppState> {
  private menuClick: boolean = false;
  private layoutMenuScroller!: ScrollPanel | null;
  private menu!: Array<null>;

  history = createBrowserHistory();

  public idleTimer;
  handleSelectChange: (event: SelectChangeEvent<"">, child: ReactNode) => void;
  public constructor(props: any) {
    super(props);
    this.state = {
      isLogged: false,
      timeout: 1000 * 20 * 60,
      showModal: false,
      userLoggedIn: false,
      isloading: true,
      isTimedOut: false,
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      iconmenuActive: true,
      userType: "",
      staffName: '',
      layoutMode: "static",
      layoutColorMode: "dark",
      role: [],
      UserDisplayName: "",
      inActive: false,
      rolepermission: [],
      rolebyid: [],
      isSkipHeader: false,
      Aaroles: "",
      roleid: "",
      openDialog: false,
      preiass: false,
      value: "",
      getPatientDataItems: null,
      selectedPatientId: '',
      newTreatmentPlan: null,
      newFormName: null,
      searchTerm: '',
      encryptPatientid: '',
      encryptVisitid: ''
    }

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);

    this.idleTimer = null;
  }

  createnewUserMenu() {
    var o = {};
    var program = new Array<any>();


    program = [
      {
        id: 3,
        label: "Forms",
        position: 23,
        icon: "userEdit",
        parentId: 0,
        command: () => {
          window.location.href = "/MettlerDynamicForm"
        }
      },
      {
        id: 4,
        label: "Q15 Safety Check",
        position: 23,
        icon: "q15checkicon",
        parentId: 0,
        command: () => {
          window.location.href = "/MettlerQ15Reports"
        }
      }
    ];


    var tree = function (program, root) {

      program.forEach(function (a) {
        a.items = o[a.id] && o[a.id].items;
        o[a.id] = a;
        o[a.parentId] = o[a.parentId] || {};
        o[a.parentId].items = o[a.parentId].items || [];
        o[a.parentId].items.push(a);
      });
      return o[root].items;
    }(program, '0');
    this.menu = tree;


  }
  componentWillMount() {
    const staffName = localStorage.getItem('StaffNamefromPD');
    if (staffName) {
      console.log("StaffName in AppTopbar:", staffName);
      this.setState({ staffName });
    } else {
      console.log("No StaffName found in localStorage");
    }
    const loggedInString = localStorage.getItem("LOGINDATA");

    if (loggedInString) {

      const loggedInData = JSON.parse(loggedInString);
      // console.log(JSON.stringify(loggedInData));
      if (loggedInData !== null) {
        if (window.location.pathname === "/" && (loggedInData.message.code === "MHC - 0200") && this.state.iconmenuActive === true) {
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer my-token',
              'My-Custom-Header': 'foobar'
            },
            body: JSON.stringify({ username: "MurV2023" })
          };

          fetch(iPAddress + '/api/signout?jwtToken=' + loggedInData.data.jwt.jwtToken, requestOptions)
            .then(response => response.json())
            .then(json => {
            })

          window.localStorage.setItem("AUTHDATA", "");
          window.localStorage.setItem("LOGINDATA", null);
          window.location.href = "/Login";
        }
        this.setState({ isLogged: true });
        this.setState({ userType: loggedInData.data?.userType?.[0] });

      } else {
        this.setState({ isLogged: false });
      }

      this.createnewUserMenu();
      this.setState({ Aaroles: "EmployerAdmin" });
      this.setState({ isloading: false });
      if (window.location.href.includes("Email")) {
        window.location.href = "/vCareDashboard";
      }
    } else {
      this.setState({ isLogged: false });
    }
  }

  onWrapperClick(event: React.MouseEvent) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false
      });
    }

    this.menuClick = false;
  }

  onToggleMenu(event: React.MouseEvent) {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === "overlay") {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive
        });
      } else if (this.state.layoutMode === "static") {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive
      });
    }

    event.preventDefault();
  }

  isDesktop(): boolean {
    return window.innerWidth > 1024;
  }

  onSidebarClick(event: React.MouseEvent) {
    this.menuClick = true;

    setTimeout(() => {
      if (this.layoutMenuScroller) {

        (this.layoutMenuScroller as any)["moveBar"]();

      }
    }, 500);
  }

  usersEdit() {
    window.location.href = "/MettlerPatientDetails";
  }

  adminPage() {
    window.location.href = "/MettlerAdminDashboard";
  }

  handleTabClose = event => {

  };
  public render() {

    window.addEventListener('beforeunload', this.handleTabClose);
    let wrapperClass = classNames("layout-wrapper", {
      "layout-overlay": this.state.layoutMode === "overlay",
      "layout-static": this.state.layoutMode === "static",
      "layout-static-sidebar-inactive":
        this.state.staticMenuInactive && this.state.layoutMode === "static",
      "layout-overlay-sidebar-active":
        this.state.overlayMenuActive && this.state.layoutMode === "overlay",
      "layout-mobile-sidebar-active": this.state.mobileMenuActive
    });

    let sidebarClassName = this.state.inActive ? classNames("layout-sidebar", {
      "layout-sidebar-dark inactive": this.state.layoutColorMode === "dark"
    }) : classNames("layout-sidebar", {
      "layout-sidebar-dark": this.state.layoutColorMode === "dark"
    });

    if ((window.location.pathname === '/MettlerPasscodePage/' || window.location.pathname === '/MettlerPasscodePage/' || window.location.pathname.includes('/MettlerPasscodePage/')) && !this.state.isSkipHeader) {
      this.setState({ isSkipHeader: true });

    }

    if ((window.location.pathname === '/Login' || window.location.pathname === '/Login' || window.location.pathname.includes('/Login')) && !this.state.isSkipHeader) {
      this.setState({ isSkipHeader: true });

    }

    if ((window.location.pathname === '/MettlerForgotPassword' || window.location.pathname === '/MettlerForgotPassword' || window.location.pathname.includes('/MettlerForgotPassword')) && !this.state.isSkipHeader) {
      this.setState({ isSkipHeader: true });

    }

    if ((window.location.pathname === '/MettlerOtpEnter' || window.location.pathname === '/MettlerOtpEnter' || window.location.pathname.includes('/MettlerOtpEnter')) && !this.state.isSkipHeader) {
      this.setState({ isSkipHeader: true });

    }
    if ((window.location.pathname === '/MettlerSetPassword' || window.location.pathname === '/MettlerSetPassword' || window.location.pathname.includes('/MettlerSetPassword')) && !this.state.isSkipHeader) {
      this.setState({ isSkipHeader: true });

    }


    if ((window.location.pathname === '/FormDynamicPage' || window.location.pathname === '/FormDynamicPage' || window.location.pathname.includes('/FormDynamicPage')) && !this.state.isSkipHeader) {
      this.setState({ isSkipHeader: true });

    }

    if ((window.location.pathname === '/MettlerAddOrganization' || window.location.pathname === '/MettlerAddOrganization' || window.location.pathname.includes('/MettlerAddOrganization')) && !this.state.isSkipHeader) {
      this.setState({ isSkipHeader: true });

    }

    const SystemAdminAndSuperAdmin = [
      { title: 'Organization Details', icon: organizationImage, link: "/MettlerOrganizationList" },
      { title: 'Staff Details', icon: groupImage, link: "/MettlerAllStaffDetailsList" },
      { title: 'Bed Master Configuration', icon: patientBedImage, link: "/MettlerBedMasterConfiguration" },
      { title: 'Patient Details', icon: userEdit, link: "/MettlerPatientDetails" },
      { title: 'Patient Staff Assign', icon: patientStaffImage, link: "/MettlerStaffPatient" },
      { title: 'Create New Form', icon: fileDocument, link: "/mettlerViewFields" },
      { title: 'Ascension File', icon: patientStaffImage, link: "/MettlerAscensionFileUpload" },
      { title: 'Ascension Patient Data', icon: userEdit, link: "/MettlerAscensionFilePatientData" },
      { title: 'Ascension Audio File Configuration', icon: fileDocument, link: "/MettlerAscensionVoiceToMp3Convert" },
      { title: ' Configuration', icon: setting, link: "/MettlerConfiguration" },
      { title: 'Admin Configuration', icon: setting, link: "/MettlerAdminDashboard" },
    ];
    const StaffAdmin = [
      { title: 'Organization Info', icon: organizationImage, link: "/MettlerOrganizationDetails" },
      { title: 'Staff Information', icon: groupImage, link: "/MettlerStaffInfoPage" },
      { title: 'Q15 Reports', icon: patientBedImage, link: "/MettlerQ15Reports" },
      { title: 'Patient Details', icon: userEdit, link: "/MettlerPatientDetails" },
      { title: 'Ascension File', icon: fileDocument, link: "/MettlerAscensionFileUpload" },
      { title: 'Ascension Patient Data', icon: userEdit, link: "/MettlerAscensionFilePatientData" },
      { title: 'Ascension Audio File Configuration', icon: fileDocument, link: "/MettlerAscensionVoiceToMp3Convert" },
      { title: 'Configuration', icon: setting, link: "/MettlerConfiguration" },
    ];
    const Patient = [
      { title: 'Patient Dashboard', icon: dashboard, link: "/MettlerPatientInfo" },
      { title: 'Patient Info', icon: groupImage, link: "/MettlerCreatePatient" },
    ];
    this.handleSelectChange = (event) => {
      this.setState({ selectedPatientId: event.target.value });
    };


    return (
      <React.Fragment>
        <Switch>
          {this.state.isSkipHeader && (
            <><div className="layout-main">
              <React.Fragment>
                <Route path="/" exact={true} component={props => <LoginPage {...props} />} />
                <Route path="/Login" exact={true} component={props => <LoginPage {...props} />} />
                <Route path="/MettlerPasscodePage" exact={true} component={props => <PasscodePage {...props} />} />
                <Route path="/MettlerPasscodePage/:id" exact={true} component={props => <PasscodePage {...props} />} />
                <Route path="/MettlerPasscodePage/:id/:mail" exact={true} component={props => <PasscodePage {...props} />} />
                <Route path="/MettlerForgotPassword" exact={true} component={props => <ForgotPasswordPage {...props} />} />
                <Route path="/MettlerForgotPassword/:hint" exact={true} component={props => <ForgotPasswordPage {...props} />} />
                <Route path="/MettlerOtpEnter" exact={true} component={props => <OtpEnterValidPage {...props} />} />
                <Route path="/MettlerOtpEnter/:hint" exact={true} component={props => <OtpEnterValidPage {...props} />} />
                <Route path="/MettlerOtpEnter/:hint/:mail" exact={true} component={props => <OtpEnterValidPage {...props} />} />
                <Route path="/MettlerSetPassword" exact={true} component={props => <SetPassword {...props} />} />
                <Route path="/MettlerSetPassword/:userName" exact={true} component={props => <SetPassword {...props} />} />
                <Route path="/MettlerAddOrganization" exact={true} component={props => <AddOrganizationPage {...props} />} />
              </React.Fragment>
            </div>
            </>
          )}

          {!this.state.isLogged && (

            <div className="layout-main">
              <React.Fragment>
                <Route path="/" exact={true} component={props => <LoginPage {...props} />} />
              </React.Fragment>
            </div>
          )}
          {this.state.isLogged && (
            <div className={wrapperClass} onClick={this.onWrapperClick}>
              {!this.state.iconmenuActive && (
                <AppTopbar onToggleMenu={this.onToggleMenu} />
              )}
              {this.state.iconmenuActive && !window.location.pathname.includes("/MettlerPatientInfo") && (
                <AppTopbar onToggleMenu={this.onToggleMenu} />
              )}
              {this.state.iconmenuActive && (
                this.state.inActive ?
                  <div className={sidebarClassName} onClick={this.onSidebarClick} >
                    {(this.state.userType === "System Admin" || this.state.userType === "Super Admin") &&
                      <>
                        <LeftPanel
                          toggle={() => this.setState({ inActive: false })}
                          menuItems={SystemAdminAndSuperAdmin} />
                      </>
                    }
                    {(this.state.userType === "Staff" || this.state.userType === "Admin") &&
                      <>
                        <LeftPanel
                          toggle={() => this.setState({ inActive: false })}
                          menuItems={StaffAdmin} />
                      </>
                    }
                    {this.state.userType === "Patient" &&
                      <>
                        <LeftPanel
                          toggle={() => this.setState({ inActive: false })}
                          menuItems={Patient} />
                      </>}
                  </div>
                  : <div className={sidebarClassName} onClick={this.onSidebarClick}
                    style={{
                      width: "60px", height: '-webkit-fill-available',
                      marginTop: window.location.pathname === "/MettlerPatientDetails/:patientId" ? "60px" : "0px",
                      zIndex: 1000
                    }}>
                    <div className="layout-sidebar-scroll-content">
                      <div className="layout-logo">
                        <div className="layout-profile" />
                        <div>
                          <div>
                            <div style={{
                              display: 'inline-flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              marginTop: '-22px',
                              position: 'relative',
                              top: '-7px'
                            }}
                              className="menu"
                            >
                              <a style={{ cursor: 'pointer' }}
                                onClick={() => this.setState({ inActive: true })}>
                                <img style={{ height: '30px', width: '59px', marginTop: '8px' }}
                                  src={rectangular} />
                              </a>
                              {(this.state.userType === "System Admin" || this.state.userType === "Super Admin") &&
                                <>
                                  <NavigationMenu menuItems={SystemAdminAndSuperAdmin} />
                                </>}
                              {(this.state.userType === "Staff" || this.state.userType === "Admin") &&
                                <>
                                  <NavigationMenu menuItems={SystemAdminAndSuperAdmin} />
                                </>}
                              {this.state.userType === "Patient" &&
                                <>
                                  <NavigationMenu menuItems={SystemAdminAndSuperAdmin} />
                                </>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              )

              }
              {this.state.iconmenuActive && (
                <div className="layout-main PatientData"
                  style={{
                    marginLeft: '60px',
                    background: window.location.pathname === "/MettlerPatientDetails" ? "#F2F4F9" : '#eaedf5'
                  }}
                >
                  <React.Fragment>
                    <Route path="/MettlerPatientInfo" exact={true} component={PatientInfoPage} />
                    <Route path="/MettlerPatientInfo/:patientId" exact={true} component={PatientInfoPage} />
                    <Route path="/MettlerAdminDashboard" exact={true} component={AdminDashboardPage} />
                    <Route path="/MettlerPatientDetails" exact={true} component={OverViewPDPage} />
                    <Route path="/MettlerPatientDetails/:staffID" exact={true} component={OverViewPDPage} />
                    <Route path="/MettlerCreatePatient" exact={true} component={CreatePatientPage} />
                    <Route path="/MettlerCreatePatient/:patientId" exact={true} component={CreatePatientPage} />
                    <Route path="/MettlerOrganizationDetails" exact={true} component={OrganizationDetailsPage} />
                    <Route path="/MettlerOrganizationDetails/:id" exact={true} component={OrganizationDetailsPage} />
                    <Route path="/MettlerStaffInfoPage" exact={true} component={StaffInfoPage} />
                    <Route path="/MettlerStaffInfoPage/:staffId" exact={true} component={StaffInfoPage} />
                    <Route path="/MettlerAllStaffDetailsList" exact={true} component={AllStaffDetailsPage} />
                    <Route path="/MettlerStaffPatient" exact={true} component={PatientStaffAssignmentPage} />
                    <Route path="/MettlerAdmitPatient" exact={true} component={AdmitPatientPage} />
                    <Route path="/MettlerBedMasterConfiguration" exact={true} component={BedMasterConfiguration} />
                    <Route path="/MettlerAdmitPatientupdated" exact={true} component={AdmitPatientupdated} />
                    <Route path="/MettlerAdmitPatientupdated/:patientId" exact={true} component={AdmitPatientupdated} />
                    <Route path="/MettlerAdmitPatientupdated/:patientId/:id" exact={true} component={AdmitPatientupdated} />
                    <Route path="/MettlerVisitPatientDetails" exact={true} component={VisitPatientDetailsPage} />
                    <Route path="/MettlerVisitPatientDetails/:id" exact={true} component={VisitPatientDetailsPage} />
                    <Route path="/MettlerPatientStaffAssignDetails" exact={true} component={PatientStaffAssignDetailsPage} />
                    <Route path="/MettlerDynamicBedAssign" exact={true} component={DynamicBedAssignPage} />
                    <Route path="/MettlerVisitPatientdata" exact={true} component={VisitPatientdata} />
                    <Route path="/MettlerBedAssignInformation" exact={true} component={BedAssignInformationPage} />
                    <Route path="/MettlerAddPatientVitals" exact={true} component={AddPatientVitals} />
                    <Route path="/MettlerAddPatientProblem" exact={true} component={AddPatientProblem} />
                    <Route path="/MettlerVisitPatientdata/:patientid/:visitId" exact={true} component={VisitPatientdata} />
                    <Route path="/MettlerAddAllergy" exact={true} component={AddAllergy} />
                    <Route path="/MettlerSkinAllergy" exact={true} component={SkinAllergy} />
                    <Route path="/MettlerPatientProblem" exact={true} component={PatientProblem} />
                    <Route path="/MettlerPatientProblem/:patientid/:visitId/:id" exact={true} component={PatientProblem} />
                    <Route path="/MettlerAddAllergy/:patientid/:visitId" exact={true} component={AddAllergy} />
                    <Route path="/MettlerAddAllergy/:patientid/:visitId/:id" exact={true} component={AddAllergy} />
                    <Route path="/MettlerSkinAllergy/:patientid/:visitId/:id" exact={true} component={SkinAllergy} />
                    <Route path="/MettlerAddPatientProblem/:patientid/:visitId" exact={true} component={AddPatientProblem} />
                    <Route path="/MettlerAddPatientProblem/:patientid/:visitId/:id" exact={true} component={AddPatientProblem} />
                    <Route path="/MettlerAddPatientVitals/:patientid/:visitId" exact={true} component={AddPatientVitals} />
                    <Route path="/MettlerAddPatientVitals/:patientid/:visitId/:id" exact={true} component={AddPatientVitals} />
                    <Route path="/MettlerAddPatientImmunization/:patientid/:visitId" exact={true} component={AddPatientImmunization} />
                    <Route path="/MettlerAddPatientImmunization/:patientid/:visitId/:id" exact={true} component={AddPatientImmunization} />
                    <Route path="/MettlerAddProcedure" exact={true} component={AddProcedure} />
                    <Route path="/MettlerAddProcedure/:patientid/:visitId" exact={true} component={AddProcedure} />
                    <Route path="/MettlerAddProcedure/:patientid/:visitId/:id" exact={true} component={AddProcedure} />
                    <Route path="/MettlerProcedureView" exact={true} component={ProcedureView} />
                    <Route path="/MettlerProcedureView/:patientid/:visitId" exact={true} component={ProcedureView} />
                    <Route path="/MettlerProcedureView/:patientid/:visitId/:id" exact={true} component={ProcedureView} />
                    <Route path="/MettlerAddImagingProcedure" exact={true} component={AddImagingProcedure} />
                    <Route path="/MettlerAddImagingProcedure/:patientid/:visitId" exact={true} component={AddImagingProcedure} />
                    <Route path="/MettlerAddImagingProcedure/:patientid/:visitId/:id" exact={true} component={AddImagingProcedure} />
                    <Route path="/MettlerCtScan" exact={true} component={ImagingProcedureOverView} />
                    <Route path="/MettlerCtScan" exact={true} component={ImagingProcedureOverView} />
                    <Route path="/MettlerCtScan/:patientid/:visitId" exact={true} component={ImagingProcedureOverView} />
                    <Route path="/MettlerCtScan/:patientid/:visitId/:id" exact={true} component={ImagingProcedureOverView} />
                    <Route path="/MettlerAddPatientLabTest" exact={true} component={AddPatientLabTest} />
                    <Route path="/MettlerAddPatientLabTest/:patientid/:visitId" exact={true} component={AddPatientLabTest} />
                    <Route path="/MettlerAddPatientLabTest/:patientid/:visitId/:id" exact={true} component={AddPatientLabTest} />
                    <Route path="/MettlerLab_TestViewOverView" exact={true} component={Lab_TestViewOverView} />
                    <Route path="/MettlerAddPatientImmunization" exact={true} component={AddPatientImmunization} />
                    <Route path="/MettlerAddImmunizationView" exact={true} component={AddImmunizationView} />
                    <Route path="/MettlerAddImmunizationView/:patientid/:visitId/:id" exact={true} component={AddImmunizationView} />
                    <Route path="/MettlerLab_TestViewOverView/:patientid/:visitId/:id" exact={true} component={Lab_TestViewOverView} />
                    <Route path="/MettlerViewProcedure" exact={true} component={ViewProcedure} />
                    <Route path="/MettlerViewProcedure/:patientid/:visitId/:id" exact={true} component={ViewProcedure} />
                    <Route path="/MettlerAddMultiVital/:patientid/:visitId" exact={true} component={AddMultiVital} />
                    <Route path="/MettlerAddMultiVital/:patientid/:visitId/:id" exact={true} component={AddMultiVital} />
                    <Route path="/MettlerQ15Reports" exact={true} component={Q15Reports} />
                    <Route path="/MettlerOrganizationList" exact={true} component={AllOrganizationList} />
                    <Route path="/mettlerViewFields" exact={true} component={ViewFields} />
                    <Route path="/mettlerViewFields/:name" exact={true} component={ViewFields} />
                    <Route path="/mettlerViewFields/:patientid/:name/:visitId" exact={true} component={ViewFields} />
                    <Route path="/MettlerAddProcedureconsult" exact={true} component={AddProcedureconsult} />
                    <Route path="/MettlerAddProcedureconsult/:patientid/:visitId" exact={true} component={AddProcedureconsult} />
                    <Route path="/MettlerAddProcedureconsult/:patientid/:visitId/:id" exact={true} component={AddProcedureconsult} />
                    <Route path="/MettlerPreAdmitFileUpload" exact={true} component={PreAdmitFileUploadPage} />
                    <Route path="/MettlerPreAdmitAssessment" exact={true} component={PreAdmitAssessmentPage} />
                    <Route path="/MettlerNursetoNurseReport" exact={true} component={NursetoNursePdf} />
                    <Route path="/MettlerNursetoNurseReport/:id" exact={true} component={NursetoNursePdf} />
                    <Route path="/MettlerMTPForm/:id" exact={true} component={MTPForm} />
                    <Route path="/MettlerConfiguration" exact={true} component={Configuration} />
                    <Route path="/MettlerConfigaddproblem" exact={true} component={Configaddproblem} />
                    <Route path="/MettlerConfigaddproblem/:id" exact={true} component={Configaddproblem} />
                    <Route path="/MettlerCreatenewschedule" exact={true} component={Createnewschedule} />
                    <Route path="/MettlerCreatenewschedule/:id" exact={true} component={Createnewschedule} />
                    <Route path="/MettlerAscensionFileUpload" exact={true} component={AscensionFileUploadPage} />
                    <Route path="/MettlerAscensionFilePatientData" exact={true} component={AscensionFilePatientDataPage} />
                    <Route path="/MettlerAscensionVoiceToMp3Convert" exact={true} component={AscensionVoiceToMp3ConvertPage} />
                    <Route path="/MettlerDynamicForm" exact={true} component={DynamicForm} />
                    <Route path="/MettlerDynamicForm/:name" exact={true} component={DynamicForm} />
                    <Route path="/MettlerDynamicForm/:patientid/:name" exact={true} component={DynamicForm} />
                    <Route path="/MettlerDynamicForm/:patientid/:name/:visitId" exact={true} component={DynamicForm} />
                    <Route path="/MettlerTemplet" exact={true} component={Templet} />
                  </React.Fragment>
                </div>
              )}
            </div>
          )}
        </Switch>
      </React.Fragment >
    );
  }
}
const ChildApp = withRouter(App as any);
export default ChildApp;

